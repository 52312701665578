import { G as GroupInfo, R as RecordForm } from "./index33.js";
import "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { g as AUDIT_CONST } from "./audit.status.js";
import "./GroupInfo.vue_vue_type_style_index_0_scoped_true_lang.js";
import "./audit.others.js";
import "./index.js";
const FILE_SIZE_B = 1;
const FILE_SIZE_KB = FILE_SIZE_B * 1024;
const FILE_SIZE_MB = FILE_SIZE_KB * 1024;
var FILE_SIZES = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  FILE_SIZE_B,
  FILE_SIZE_KB,
  FILE_SIZE_MB
}, Symbol.toStringTag, { value: "Module" }));
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Measurement", "scroll": { x: 1550 }, "custom-url": _vm.customApiUrl, "has-filter-inactive": false, "has-active": false, "has-delete": true, "has-action": !_vm.status } }, [_c("text-field", { key: "LoggerType", attrs: { "data-index": "loggerType", "title": "Logger Type", "fixed": "left", "width": 115, "sorter": true } }), _c("text-field", { key: "LoggerNumber", attrs: { "data-index": "loggerNumber", "title": "Logger Number", "sorter": true } }), _c("text-field", { key: "PositionInRetort", attrs: { "data-index": "positionInRetort", "title": "Position In Retort", "sorter": true } }), _c("text-field", { key: "FillWeight", attrs: { "data-index": "fillWeight", "title": "Fill Weight", "sorter": true } }), _c("text-field", { key: "FillMeat", attrs: { "data-index": "fillMeat", "title": "Fill Meat", "sorter": true } }), _c("text-field", { key: "NetWeight", attrs: { "data-index": "netWeight", "title": "Net Weight", "sorter": true } }), _c("text-field", { key: "EquivalentWeight", attrs: { "data-index": "equivalentWeight", "title": "Equivalent Weight", "sorter": true } }), _c("text-field", { key: "InitialHeadspace", attrs: { "data-index": "initialHeadspace", "title": "Initial Headspace", "sorter": true } }), _c("text-field", { key: "Vacuum", attrs: { "data-index": "vacuum", "title": "Vacuum", "sorter": true } }), _c("text-field", { key: "FinalHeadspace", attrs: { "data-index": "finalHeadspace", "title": "Final Headspace", "sorter": true } }), _c("text-field", { key: "InitialTemp", attrs: { "data-index": "initialTemp", "title": "Initial Temp", "sorter": false } }), _c("text-field", { key: "FoCook", attrs: { "data-index": "foCook", "title": "Fo Cook", "sorter": false } }), _c("text-field", { key: "FoTotal", attrs: { "data-index": "foTotal", "title": "Fo Total", "sorter": false } }), _c("text-field", { key: "Comments", attrs: { "data-index": "comments", "title": "Comment", "sorter": false } })], 1);
};
var staticRenderFns$5 = [];
var ListMeasurement_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "[data-v-58dcbe5f] .list__title{padding:0;background-color:transparent}[data-v-58dcbe5f] .list__title h2{font-size:16px}[data-v-58dcbe5f] .list__table{margin:-3rem -2rem 0}\n")();
const apiUrl$5 = "#{VUE_APP_API_URL}#";
const __vue2_script$5 = {
  name: "ListMeasurement",
  inject: ["status"],
  data() {
    const { recordId } = this.$route.params;
    const { groupId, itemId } = this.$route.query;
    const customApiUrl = `${apiUrl$5}/sterilization/audit-groups/${groupId}/items/${itemId}/histories/${recordId}/measurements`;
    return {
      apiUrl: apiUrl$5,
      customApiUrl
    };
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "58dcbe5f", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ListMeasurement = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-measurement-container" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.items.histories.measurements", "api-url": _vm.apiUrl, "create-route": _vm.status ? null : _vm.preRoute + "/create-measurement?groupId=" + _vm.groupId + "&itemId=" + _vm.itemId, "edit-route": _vm.preRoute + "/measurement/:id?groupId=" + _vm.groupId + "&itemId=" + _vm.itemId, "list": _vm.ListMeasurement } })], 1);
};
var staticRenderFns$4 = [];
const apiUrl$4 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  name: "ListMeasurementContainer",
  provide() {
    return {
      status: this.status
    };
  },
  props: {
    status: {
      type: Number,
      default: 0
    }
  },
  data() {
    const { groupId, itemId } = this.$route.query;
    const { recordId } = this.$route.params;
    const preRoute = `/sterilization-audits/audit-groups/audit-record/${recordId}`;
    return {
      apiUrl: apiUrl$4,
      ListMeasurement,
      groupId,
      itemId,
      recordId,
      preRoute
    };
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ListMeasurementContainer = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "upload-document" }, [_c("a-card", { staticClass: "audit-documents mt-4", scopedSlots: _vm._u([{ key: "title", fn: function() {
    return [_c("div", { staticClass: "d-flex align-items-center justify-content-between" }, [_c("div", { staticClass: "h6 text--gray-10" }, [_vm._v("Documents")]), _c("a-upload", { attrs: { "name": "file", "multiple": true, "action": _vm.uploadUrl, "show-upload-list": false, "before-upload": _vm.beforeUpload }, on: { "change": _vm.onChange } }, [_c("a-button", [_c("a-icon", { attrs: { "type": "upload" } }), _vm._v("Upload ")], 1)], 1)], 1)];
  }, proxy: true }]) }, [_c("div", { staticClass: "ant-upload-list ant-upload-list-text" }, [_vm.isLoading ? [_c("p", [_vm._v("Loading...")])] : _vm._l(_vm.auditDocs, function(doc, index2) {
    return _c("div", { key: index2, staticClass: "ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text mt-0 mb-3" }, [_c("div", { staticClass: "ant-upload-list-item-info d-flex align-items-center px-0" }, [_c("a-icon", { attrs: { "type": "paper-clip" } }), _c("a", { staticClass: "ant-upload-list-item-name ant-upload-list-item-name-icon-count-1", attrs: { "target": "_blank", "href": doc.domain + doc.path } }, [_vm._v(_vm._s(doc.name))]), _c("a-icon", { staticClass: "ml-auto", attrs: { "type": "close" }, on: { "click": function($event) {
      return _vm.delAuditDoc(doc.id);
    } } })], 1)]);
  })], 2)])], 1);
};
var staticRenderFns$3 = [];
var UploadDocument_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".audit-documents[data-v-543c7e15] .ant-card-head-title{padding-bottom:8px}.audit-documents[data-v-543c7e15] .ant-card-body{max-height:350px;overflow-x:hidden;overflow-y:auto}.audit-documents[data-v-543c7e15] .ant-upload-list-item:hover .ant-upload-list-item-info{background:transparent}\n")();
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$3 = {
  name: "UploadDocument",
  inject: ["crud"],
  props: {
    recordData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      FILE_SIZES,
      apiUrl: apiUrl$3,
      uploadUrl: apiUrl$3 + "/files/documents",
      isLoading: true
    };
  },
  computed: {
    auditDocs() {
      return this.crud.getList();
    }
  },
  watch: {
    recordData(val) {
      if (val && val.groupId && val.auditCycleId && val.itemId) {
        this.fetchAuditDocs();
      }
    }
  },
  methods: {
    async fetchAuditDocs() {
      const { groupId, auditCycleId, itemId } = this.recordData;
      const docApiUrl = `${this.apiUrl}/sterilization/audit-documents/groups/${groupId}/cycles/${auditCycleId}/items/${itemId}`;
      this.isLoading = true;
      await this.crud.fetchList(docApiUrl);
      this.isLoading = false;
    },
    beforeUpload(file) {
      const isLt1M = file.size / FILE_SIZE_MB <= 1;
      if (!isLt1M) {
        this.$message.error("File upload must smaller than 1MB!");
      }
      return isLt1M;
    },
    onChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "error") {
        this.$message.error("Upload failed, please try again");
      }
      if (info.file.status === "done") {
        this.storeValue("domain", this.apiUrl);
        this.storeValue("path", info.file.response.path);
        this.storeValue("name", info.file.name);
        this.storeValue("auditGroupId", this.recordData.groupId);
        this.storeValue("auditCycleId", this.recordData.auditCycleId);
        this.storeValue("auditItemId", this.recordData.itemId);
        this.crud.submitEntity("create").then(() => {
          this.fetchAuditDocs();
        });
      }
    },
    delAuditDoc(docId) {
      this.crud.destroyEntity(`${this.apiUrl}/sterilization/audit-documents?id=${docId}`, docId).then(() => this.fetchAuditDocs());
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "543c7e15", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var UploadDocument$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "upload-document-container" }, [_c("resource", { attrs: { "name": "sterilization.audit-documents", "api-url": _vm.apiUrl } }, [_c("upload-document", { attrs: { "record-data": _vm.recordData } })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  name: "UploadDocumentIndex",
  components: {
    UploadDocument: UploadDocument$1
  },
  props: {
    recordData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      apiUrl: apiUrl$2
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var UploadDocument = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-audit-record" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("page-header", { attrs: { "title": "Audit Record - " + _vm.dataItem.itemCode, "back": _vm.backTo, "has-tag": _vm.recordEntity.status >= 0 } }, [_c("template", { slot: "tag-content" }, [_c("tag-field", { attrs: { "value": _vm.AUDIT_CONST.AUDIT_RECORD_STATUS_TEXTS[_vm.recordEntity.status], "color": _vm.AUDIT_CONST.AUDIT_RECORD_STATUS_COLORS[_vm.recordEntity.status] } })], 1)], 2), _c("div", { staticClass: "mx-5 my-5" }, [_c("a-row", { attrs: { "gutter": [24, 32] } }, [_c("a-col", { attrs: { "span": 16 } }, [_c("record-form", { on: { "updated": _vm.refreshRecord } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("group-info", { attrs: { "item-id": _vm.itemId, "group-data": _vm.groupEntity } }), _c("upload-document", { attrs: { "record-data": _vm.recordData } })], 1)], 1), _c("a-row", [_c("a-col", { staticClass: "py-3" }, [_c("list-measurement-container", { key: _vm.recordEntity.status, attrs: { "status": _vm.recordEntity.status } })], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var EditAuditRecord_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".audit-documents[data-v-38359fd3] .ant-card-head-title{padding-bottom:8px}.audit-documents[data-v-38359fd3] .ant-card-body{max-height:503px;overflow-x:hidden;overflow-y:auto}.audit-documents[data-v-38359fd3] .ant-upload-list-item:hover .ant-upload-list-item-info{background:transparent}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditAuditRecord",
  inject: ["resourceProps"],
  components: {
    GroupInfo,
    ListMeasurementContainer,
    RecordForm,
    UploadDocument
  },
  data() {
    const [groupProps, historyProps] = this.resourceProps;
    const { recordId } = this.$route.params;
    const { groupId, itemId } = this.$route.query;
    const groupCustomApiUrl = `${apiUrl$1}/sterilization/audit-groups/${groupId}`;
    const historyCustomApiUrl = `${groupCustomApiUrl}/items/${itemId}/histories/${recordId}`;
    return {
      AUDIT_CONST,
      FILE_SIZES,
      apiUrl: apiUrl$1,
      groupCustomApiUrl,
      historyCustomApiUrl,
      groupProps,
      historyProps,
      recordId,
      groupId,
      itemId,
      dataItem: {
        id: null,
        itemCode: ""
      },
      preItems: [
        {
          title: "Data Entry",
          path: ""
        },
        {
          title: "Audit Group",
          path: "/sterilization-audits/audit-groups"
        }
      ],
      recordData: {}
    };
  },
  computed: {
    groupEntity() {
      return this.groupProps.crud.getEntity(this.groupId) || {};
    },
    recordEntity() {
      return this.historyProps.crud.getEntity(this.recordId) || {};
    },
    menuItems() {
      return [
        ...this.preItems,
        {
          title: this.groupEntity.groupName,
          path: `/sterilization-audits/audit-groups/${this.groupEntity.id}`
        },
        {
          title: `${this.dataItem.itemCode}`,
          path: `/sterilization-audits/audit-groups/${this.groupEntity.id}/item/${this.dataItem.id}`
        },
        {
          title: `Audit Record - ${this.dataItem.itemCode}`,
          path: ""
        }
      ];
    }
  },
  async mounted() {
    await Promise.all([
      this.groupProps.crud.fetchEntity(this.groupCustomApiUrl),
      this.historyProps.crud.fetchEntity(this.historyCustomApiUrl)
    ]);
    if (this.groupEntity && this.groupEntity.auditItems && this.itemId && this.recordEntity) {
      this.dataItem = this.groupEntity.auditItems.find((item) => item.id == this.itemId);
      this.recordData = {
        groupId: this.groupEntity.id,
        auditCycleId: this.recordEntity.auditCycleId,
        itemId: this.itemId
      };
    }
  },
  methods: {
    backTo() {
      const back = `/sterilization-audits/audit-groups/${this.groupEntity.id}/item/${this.dataItem.id}`;
      this.$router.push(back);
    },
    refreshRecord() {
      this.historyProps.crud.fetchEntity(this.historyCustomApiUrl);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "38359fd3", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditAuditRecord = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-audit-record-container" }, [_c("resource", { attrs: { "name": [
    "sterilization.audit-groups",
    "sterilization.audit-groups.items.histories"
  ], "api-url": _vm.apiUrl, "create": _vm.EditAuditRecord } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditAuditRecordIndex",
  data() {
    return {
      apiUrl,
      EditAuditRecord
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
